












































































































































































































































































































































































































import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { omitEmpties, openFilePicker, Pagination } from 'vuelpers'
import { Attachment, BackendError, Category, User, VFormRef } from '@/types'
import { getValidators } from '@/mixins/FormMixin'
import { Accessors } from 'vue/types/options'

const newCategory = (v: Partial<Category> = {}): Partial<Category> => ({
	iListOrder: v?.iListOrder,
	iTotalItems: v?.iTotalItems,
	vDataTable: v.vDataTable || '',
	vSpecial: v.vSpecial || '',
	vImageAlt: v.vImageAlt || '',
	vKeywords: v.vKeywords || '',
	vFilter1: v.vFilter1 || '',
	vFilter2: v.vFilter2 || '',
	vFilter3: v.vFilter3 || '',
	vFilter4: v.vFilter4 || '',
	vFilter5: v.vFilter5 || '',
	vFilter6: v.vFilter6 || '',
	vFilter7: v.vFilter7 || '',
	vFilter8: v.vFilter8 || '',
	iCategoryId: v.iCategoryId || undefined,
	vCategory: v.vCategory || '',
	vImg: v.vImg || '',
	iParent: v?.iParent,
})

export default Vue.extend({
	name: 'CategoryForm',
	props: {
		categoryData: Object as Vue.PropType<Category>,
		onView: {
			default: false,
			type: Boolean as Vue.PropType<boolean>,
		},
		onUpdate: {
			default: false,
			type: Boolean as Vue.PropType<boolean>,
		},
	},

	data() {
		return {
			vCategory: '',
			category: newCategory(),
			error: {} as BackendError,
			rules: getValidators('required', 'email', 'password'),
			showAttachmentError: false,
			vImg: null as Partial<Attachment> | null,
		}
	},
	watch: {
		categoryData: {
			deep: true,
			immediate: true,
			handler(v: Category) {
				this.category = newCategory(v)
				this.vCategory = v.vCategory
				this.showAttachmentError = false
				if (this.categoryData.vImg) {
					this.vImg = {
						src: this.categoryData.vImg,
					}
				}
			},
		},
	},
	created() {
		!this.$allCategories.data.length &&
			this.getAllCategories({
				per_page: '500',
				with_columns: 'categories:*',
				order_by_value: 'asc',
			})
	},
	computed: {
		...({
			...mapGetters('auth', ['$currentUser']),
			...mapGetters('category', ['$allCategories']),
		} as Accessors<{
			$currentUser: User
			$allCategories: Pagination<Category>
		}>),
	},
	methods: {
		...mapActions('category', ['getAllCategories']),
		onResetUserForm() {
			this.vImg = null
			;(this.$refs.formRef as VFormRef).reset()
			this.$emit('closeModal')
		},
		validateCategory(): any {
			if (!this.vImg?.file && !this.onUpdate) {
				return (this.showAttachmentError = true)
			}

			const formRef = this.$refs.formRef as VFormRef
			if (!formRef.validate()) return

			this.$emit(
				this.onUpdate ? 'updateCategory' : 'createCategory',
				omitEmpties({
					...this.category,
					vImg: this.vImg?.file,
				})
			)

			this.onResetUserForm()
		},
		onClickUploadImage() {
			openFilePicker({}, async (image: Attachment) => {
				this.vImg = image
			})
		},
	},
})
