

































































































































































































import Vue from 'vue'

import { Category, Id } from '@/types'
import { Accessors } from 'vue/types/options'
import { mapActions, mapGetters } from 'vuex'
import { toFormData, omitEmpties, Pagination } from 'vuelpers'

import CategoryFrom from '@/components/Forms/CategoryFrom.vue'

export default Vue.extend({
	metaInfo: {
		title: 'Categories | Admin | zx55',
	},
	components: {
		CategoryFrom,
	},
	data() {
		return {
			expanded: [],
			property: 'value',
			headers: [
				{ text: 'List Order', value: 'iListOrder' },
				{ text: 'Category Name', value: 'vCategory' },
				{ text: 'Type', value: 'type' },
				{ text: 'Total', value: 'iTotalItems' },
				{ text: 'Data Table', value: 'vDataTable' },
				{ text: 'Key Words', value: 'vKeywords' },
				{ text: 'Actions', value: 'actions' },
			],
			actions: [
				{
					icon: 'mdi-eye',
					text: 'View',
					event: 'view',
				},

				{
					icon: 'mdi-pencil',
					text: 'Edit',
					event: 'update',
				},
				{
					icon: 'mdi-delete',
					text: 'delete',
					event: 'delete',
				},
			],
			categoryForm: {
				dialog: false,
				data: {},
			},
			onView: false,
			onUpdate: false,
			confirmDialog: {
				show: false,
				id: null as Id | null,
			},
		}
	},
	computed: {
		...(mapGetters('category', ['$categories']) as Accessors<{
			$categories: Pagination<Category>
		}>),
	},
	methods: {
		...mapActions('category', [
			'getAdminCategories',
			'getCategoryById',
			'createCategory',
			'updateCategory',
			'deleteCategoryById',
		]),
		onViewCategory(item: Category) {
			this.categoryForm.dialog = true
			this.categoryForm.data = item
			this.onView = true
		},
		onOpenUpdateModal(item: Category) {
			this.categoryForm.dialog = true
			this.categoryForm.data = item
			this.onUpdate = true
		},
		onAddCategory(item: Category) {
			this.categoryForm.dialog = true
			this.categoryForm.data = {
				iParent: item.iCategoryId,
			}
		},
		async handleUpdateCategory(item: Category) {
			const data = toFormData(omitEmpties(item), { _method: 'PATCH' })
			// console.log('handleUpdateCategory', data)
			// data = toFormData(item)
			let [err] = await this.updateCategory(data)
			if (!err) {
				this.categoryForm.dialog = false
				this.$notify({
					group: 'foo',
					title: 'Success',
					type: 'success',
					text: 'Category updated Successfully',
				})
			} else {
				this.$notify({
					group: 'foo',
					title: 'Error',
					type: 'error',
					text: 'Error in updating Category',
				})
			}
		},
		async onConfirmDeleteCategory(item: Category) {
			this.confirmDialog.show = true
			this.confirmDialog.id = item.iCategoryId
		},
		async handleDeleteCategory() {
			let [err] = await this.deleteCategoryById(this.confirmDialog.id)
			if (!err) {
				this.$notify({
					group: 'foo',
					title: 'Success',
					type: 'success',
					text: 'Category Deleted Successfully',
				})
				this.confirmDialog.show = false
			} else {
				this.$notify({
					group: 'foo',
					title: 'Error',
					type: 'error',
					text: 'Error in Deleting Category',
				})
			}
		},
		async handleCreateCategory(item: Category) {
			let data = toFormData(omitEmpties(item))
			let [err] = await this.createCategory(data)
			if (!err) {
				this.categoryForm.dialog = false
				this.$notify({
					group: 'foo',
					title: 'Success',
					type: 'success',
					text: 'Category Created Successfully',
				})
			} else {
				this.$notify({
					group: 'foo',
					title: 'Error',
					type: 'error',
					text: 'Error in Creating Category',
				})
			}
		},
		onCloseModal() {
			this.onView = false
			this.onUpdate = false
			this.categoryForm = {
				dialog: false,
				data: {},
			}
			setTimeout(() => {
				this.onView = false
				this.onUpdate = false
			}, 1000)
		},
	},
})
